<template>
  <div>
    <b-modal
      v-model="deleteElement"
      :title="$t('Delete Old')"
      :ok-title="$t('Delete')"
      :cancel-title="$t('cancel')"
      ok-variant="danger"
      cancel-variant="primary"
      @close="deleteElement=false"
      @cancel="deleteElement=false"
      @ok="ConfirmDelete"
    >
      <b-badge
        class="mt-1"
        variant="light-danger"
      >
        {{ $t('are you sure you want to delete this Old') }}
      </b-badge>
    </b-modal>
    <h3 class="mb-3">
      {{ $t('Olds') }}
    </h3>
    <b-row>
      <b-col cols="6">
        <b-button
          v-if="haveAccess('Create Old')"
          variant="gradient-success"
          @click="$router.push({name:'old.create'})"
        >
          {{ $t('Create new') }}
        </b-button>
      </b-col>
      <b-col
        cols="6"
        class="d-flex justify-content-end"
      >
        <b-button
          v-if="haveAccess(['Show Old Trash','Show Old Trash Owner'])"
          variant="gradient-danger"
          @click="$router.push({name:'old.trashed'})"
        >
          <font-awesome-icon
            :icon="['fas', 'trash']"
          />
          {{ $t('Trashed') }}
        </b-button>
      </b-col>
    </b-row>

    <b-card
      class="mt-5"
    >
      <b-card-title>
        <b-row>
          <b-col cols="8">
            <h3>
              {{ $t('checkks') }}
            </h3>
          </b-col>
          <b-col
            cols="4"
          >
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                :placeholder="$t('Type to Search')"
              />
            </b-input-group>
          </b-col>
        </b-row>
      </b-card-title>
      <b-table
        v-if="items.length>0"
        :fields="fields"
        responsive="sm"
        :items="items"
        class="table-w-max"
      >
        <template #cell(id)="data">
          <b-badge
            pill
            variant="light-primary"
          >
            {{ data.item.no }}
          </b-badge>
        </template>
        <template
          #cell(created_at_updated_at)="data"
        >
          <div>
            <b-badge
              v-if="data.item.created_at"
              v-b-tooltip.hover="{title:data.item.created_at,variant:'success',placement:'top'}"
              variant="light-success"
            >
              <font-awesome-icon
                :icon="['fas', 'table']"
              />
              {{ data.item.created_from }}
            </b-badge>
            <b-badge
              v-if="data.item.updated_from"
              v-b-tooltip.hover="{ title:data.item.updated_from,variant:'warning',placement:'top'}"
              variant="light-warning"
              class="ml-1"
            >
              <font-awesome-icon
                :icon="['fas', 'calendar-week']"
              />
              {{ data.item.updated_from }}
            </b-badge>
          </div>
        </template>
        <template #cell(carr)="data">
          <b-badge
            pill
            :variant="data.item.carr_data?'light-primary':'light-danger'"
          >
            {{ data.item.carr_data?data.item.carr_data.manufacturer+' ( '+data.item.carr_data.model +' )':'ليست مرتبطة' }}
          </b-badge>
        </template>
        <template #cell(expiration_date)="data">
          <b-badge
            pill
            variant="light-primary"
          >
            <font-awesome-icon
              :icon="['fas', 'table']"
            />
            {{ data.value }}
          </b-badge>
        </template>

        <template #cell(owner_type)="data">
          <b-badge
            pill
            :variant="data.item.owner_type?'light-primary':'light-danger'"
          >
            {{ data.item.owner_type? 'خاصة':'عامة' }}
          </b-badge>
        </template>
        <template #cell(carr_data)="data">
          <b-badge
            pill
            :variant="data.item.carr_data?'light-primary':'light-danger'"
          >
            {{ data.item.carr_data?data.item.carr_data.manufacturer+' ( '+data.item.carr_data.model +' )':'ليست مرتبطة' }}
          </b-badge>
        </template>
        <template #cell(wheel)="data">
          <b-badge
            pill
            :variant="data.item.wheel?'light-primary':'light-danger'"
          >
            {{ data.item.wheel?'مقبول':'مرفوض' }}
          </b-badge>
        </template>
        <template #cell(light)="data">
          <b-badge
            pill
            :variant="data.item.light?'light-primary':'light-danger'"
          >
            {{ data.item.light?'مقبول':'مرفوض' }}
          </b-badge>
        </template>
        <template #cell(brake)="data">
          <b-badge
            pill
            :variant="data.item.brake?'light-primary':'light-danger'"
          >
            {{ data.item.brake?'مقبول':'مرفوض' }}
          </b-badge>
        </template>
        <template #cell(combustion)="data">
          <b-badge
            pill
            :variant="data.item.combustion?'light-primary':'light-danger'"
          >
            {{ data.item.combustion?'مقبول':'مرفوض' }}
          </b-badge>
        </template>
        <template #cell(first_check)="data">
          <b-badge
            pill
            :variant="data.item.first_check?'light-primary':'light-danger'"
          >
            {{ data.item.first_check?'مقبول':'مرفوض' }}
          </b-badge>
        </template>

        <template #cell(owwner_data)="data">
          <b-badge
            pill
            :variant="data.item.owwner_data?'light-primary':'light-danger'"
          >
            {{ data.item.owwner_data?data.item.owwner_data.owner:'ليست مرتبطة' }}
          </b-badge>
        </template>

        <template #cell(fuel_type)="data">
          {{ $t(data.item.fuel_type) }}
        </template>
        <template #cell(car_type)="data">
          {{ $t(data.item.car_type) }}
        </template>
        <template #cell(platee)="data">
          <b-badge
            pill
            :variant="data.item.platee_data?'light-primary':'light-danger'"
          >
            {{ data.item.platee_data?data.item.platee_data.number:'ليست مرتبطة' }}
          </b-badge>
        </template>
      </b-table>
      <b-row
        v-else
        class="d-flex justify-content-center"
      >
        <b-col
          cols="4"
        >
          <b-alert
            variant="danger"
            show
          >
            <div class="alert-body text-center">
              <span>{{ $t('No Data Available') }}</span>
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end mt-2"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            per-page="10"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
    <explain />
  </div>
</template>

<script>

export default {
  data() {
    return {
      deleteElement: false,
      filter: null,
      element: null,
      currentPage: 1,
      totalRows: 0,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: this.$t('id') },
        { key: 'No', label: this.$t('CkeckkNo') },
        { key: 'chassis_number', label: this.$t('chassis_number') },
        { key: 'chassis_number_type', label: this.$t('chassis_number_type') },
        { key: 'owner_type', label: this.$t('owner_type') },
        { key: 'created_year', label: this.$t('created_year') },
        { key: 'first_registeraion', label: this.$t('first_registeraion') },
        { key: 'cabin_number', label: this.$t('cabin_number') },
        { key: 'cabin_number_type', label: this.$t('cabin_number_type') },
        { key: 'cabin_reserve_number', label: this.$t('cabin_reserve_number') },
        { key: 'cabin_reserve_number_type', label: this.$t('cabin_reserve_number_type') },
        { key: 'motor_number', label: this.$t('motor_number') },
        { key: 'motor_number_type', label: this.$t('motor_number_type') },
        { key: 'motor_reserve_number', label: this.$t('motor_reserve_number') },
        { key: 'motor_reserve_number_type', label: this.$t('motor_reserve_number_type') },
        { key: 'motor_manufacturer', label: this.$t('motor_manufacturer') },
        { key: 'motor_model', label: this.$t('motor_model') },
        { key: 'car_type', label: this.$t('car_type') },
        { key: 'fuel_type', label: this.$t('fuel_type') },
        { key: 'color', label: this.$t('color') },
        { key: 'fee', label: this.$t('Fee') },
        { key: 'expiration_date', label: this.$t('expiration_date') },
        { key: 'brake', label: this.$t('brake') },
        { key: 'light', label: this.$t('light') },
        { key: 'combustion', label: this.$t('combustion') },
        { key: 'wheel', label: this.$t('wheel') },
        { key: 'owwner_data', label: this.$t('owwner') },
        { key: 'carr_data', label: this.$t('car') },
        { key: 'platee', label: this.$t('plate') },
        { key: 'notes', label: this.$t('notes') },
        { key: 'created_at_updated_at', label: this.$t('created_at_updated_at') },
        { key: 'options', label: this.$t('options') },
      ]
    },
    items() {
      return this.$store.getters['checkk/GetElements']
    },
    pagination() {
      return `page=${this.currentPage}&search=${this.filter}`
    },
  },
  watch: {
    currentPage() {
      this.getElement()
    },
    filter() {
      this.currentPage = 1
      this.getElement()
    },
  },
  created() {
    this.getElement()
  },
  methods: {
    getElement() {
      this.$store.dispatch('checkk/GetElements', `page=${this.currentPage}&search=${this.filter}`).then(response => {
        this.totalRows = response.data.paginate.total
        this.currentPage = response.data.paginate.current_page
      })
    },
    Delete(val) {
      this.deleteElement = true
      this.element = val
    },
    ConfirmDelete() {
      this.$store.dispatch('checkk/Delete', this.element)
    },
  },
}
</script>
